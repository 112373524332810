import axios from "./requests";

// 登录
export const Login = (data) => {
	return axios({
		url: "/admin/login",
		method: "post",
		data,
		config: {
			headers: {
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 系统主页接口
export const Index = (data) => {
	return axios({
		url: "/index/index",
		method: "post",
		data,
		config: {
			headers: {
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 修改密码
export const Alter = (data) => {
	return axios({
		url: "/admin/changePassword",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 组织管理
export const tissue = (data) => {
	return axios({
		url: "/union/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取组织列表不含归档
export const Zzlist = (data) => {
	return axios({
		url: "/union/wholeList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 获取组织列表正常与归档
export const Zzzlist = (data) => {
	return axios({
		url: "/union/allList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				// timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 获取组织列表只包含归档
export const ZzZlist = (data) => {
	return axios({
		url: "/union/historyList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 获取组织列表正常工会
export const ZClist = (data) => {
	return axios({
		url: "/union/manageUnionList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 添加组织管理列表
export const Addlist = (data) => {
	return axios({
		url: "/union/addUnion",
		method: "post",
		data,
		config: {
			headers: {
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取组织信息
export const Obtain = (data) => {
	return axios({
		url: "/union/unionInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 编辑保存组织信息
export const Redact = (data) => {
	return axios({
		url: "/union/updateUnion",
		method: "post",
		data,
		config: {
			headers: {
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 删除组织
export const Deletezz = (data) => {
	return axios({
		url: "/union/deleteUnion",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会员列表
export const Hylist = (data) => {
	return axios({
		url: "/user/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 导入会员列表
export const Hyguide = (data) => {
	return axios({
		url: "/user/importUsersList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				// timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 会员导入失败接口
export const HYdefeated = (data) => {
	return axios({
		url: "/user/importUsersErrorList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 会员导出
export const HYtotal = (data) => {
	return axios({
		url: "/user/exportUsersExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 下载导入模板
export const HYtemplate = (data) => {
	return axios({
		url: "/user/downloadExcelExample",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 会员批量导入
export const HYdaoru = (data) => {
	return axios({
		url: "/user/uploadUsersExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "multipart/form-data",
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会员编号前缀
export const Prefix = (data) => {
	return axios({
		url: "/user/userNamePrefix",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会员添加
export const Hyadd = (data) => {
	return axios({
		url: "/user/addUser",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 编辑会员
export const Bjedit = (data) => {
	return axios({
		url: "user/userInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 编辑会员提交
export const Bjsave = (data) => {
	return axios({
		url: "user/updateUser",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 设置离职归档
export const Eonhole = (data) => {
	return axios({
		url: "user/userArchives",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 取消归档会员列表
export const Cancel = (data) => {
	return axios({
		url: "user/archivesList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 取消归档会员
export const Abolish = (data) => {
	return axios({
		url: "user/cancelArchives",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会动态列表
export const Statelist = (data) => {
	return axios({
		url: "news/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会上传图片
export const Pictures = (data) => {
	return axios({
		url: "news/saveImage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'multipart/form-data',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会动态提交
export const GHsubmit = (data) => {
	return axios({
		url: "news/addNews",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'multipart/form-data',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会动态编辑
export const GHredact = (data) => {
	return axios({
		url: "news/newsInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会动态编辑提交
export const GHedit = (data) => {
	return axios({
		url: "news/updateNews",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 工会动态删除
export const GHdelete = (data) => {
	return axios({
		url: "news/deleteNews",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进典型

// 先进典型列表
export const XJlist = (data) => {
	return axios({
		url: "model/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进类型添加
export const XJadd = (data) => {
	return axios({
		url: "model/addModel",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取先进类型编辑信息
export const XJedit = (data) => {
	return axios({
		url: "model/modelInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 保存先进类型编辑信息
export const XJsave = (data) => {
	return axios({
		url: "model/updateModel",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 删除先进类型
export const XJdelete = (data) => {
	return axios({
		url: "model/deleteModel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 当前先进类型提示内容
export const XJhint = (data) => {
	return axios({
		url: "model/tipsInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 提交先进类型提示内容
export const XJdescribe = (data) => {
	return axios({
		url: "model/tips",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 先进典型人物列表
export const XJfigure = (data) => {
	return axios({
		url: "model/character/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进典型人物添加
export const XJperson = (data) => {
	return axios({
		url: "model/character/addCharacter",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进典型人物获取编辑信息
export const XJcompile = (data) => {
	return axios({
		url: "model/character/characterInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进典型人物保存编辑信息
export const XJpreserve = (data) => {
	return axios({
		url: "model/character/updateCharacter",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 先进典型人物删除编辑信息
export const XJremove = (data) => {
	return axios({
		url: "model/character/deleteCharacter",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步运动
export const JBlist = (data) => {
	return axios({
		url: "walk/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步运动获取当前设置信息
export const JBset = (data) => {
	return axios({
		url: "walk/standardInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步运动提交当前设置信息
export const JBsetting = (data) => {
	return axios({
		url: "walk/updateStandard",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步运动历史步数
export const JBhistory = (data) => {
	return axios({
		url: "walk/userWalkInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步按日统计数据
export const ARList = (data) => {
	return axios({
		url: "walk/queryUserDayWalkPage",
		method: "post",
		data,
		responseType: 'blob',
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步按日到处数据
export const ARexport = (data) => {
	return axios({
		url: "walk/exportDayExcel",
		method: "post",
		data,
		responseType: 'blob',
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 健步运动导出数据
export const JBexport = (data) => {
	return axios({
		url: "walk/exportExcel",
		method: "post",
		data,
		responseType: 'blob',
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 答题问卷列表
export const DTlist = (data) => {
	return axios({
		url: "ques/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷添加
export const DTadd = (data) => {
	return axios({
		url: "ques/addQues",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷导出接口
export const DTderive = (data) => {
	return axios({
		url: "/ques/exportQuesUsersExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷获取编辑信息
export const DTcompile = (data) => {
	return axios({
		url: "ques/quesInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷获取编辑信息提交
export const DTpresent = (data) => {
	return axios({
		url: "ques/updateQues",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷获取删除
export const DTdelete = (data) => {
	return axios({
		url: "ques/deleteQues",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷会员统计
export const DTtotal = (data) => {
	return axios({
		url: "ques/quesAnswerUsersPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷会员答题记录
export const DTrecord = (data) => {
	return axios({
		url: "ques/quesAnswerUsersInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 答题问卷会总数居
export const DTdata = (data) => {
	return axios({
		url: "ques/quesAnswerInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 答题问卷填写之内容
export const DTfill = (data) => {
	return axios({
		url: "ques/quesInputValueList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动列表
export const eventRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/queryActivityList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动报名添加
export const addRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/saveActivity",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动报名编辑
export const editRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/editActivity",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动报名详情
export const infoRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/queryActivity",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//活动报名列表
export const inRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/queryActivityRegistrationList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//活动报名列表导出
export const outRegistration = (data) => {
	return axios({
		url: "/api/v1/activity/exportActivityRegistrationExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//节目列表
export const inProgram = (data) => {
	return axios({
		url: "/api/v1/activity/queryProgramList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//节目列表导出
export const outProgram = (data) => {
	return axios({
		url: "/api/v1/activity/exportActivityRegistrationExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//活动报名新增上传接口
export const bmPictures = (data) => {
	return axios({
		url: "/api/v1/activity/saveImage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动节目添加
export const addProgram = (data) => {
	return axios({
		url: "/api/v1/activity/saveProgram",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动节目编辑
export const editProgram = (data) => {
	return axios({
		url: "/api/v1/activity/editProgram",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//日常活动节目详情
export const infoProgram = (data) => {
	return axios({
		url: "/api/v1/activity/queryProgram",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//打分列表
export const inScoring = (data) => {
	return axios({
		url: "/api/v1/activity/queryFractionList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//打分列表导出
export const outScoring = (data) => {
	return axios({
		url: "/api/v1/activity/exportActivityFractionExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//获取平均评分列表
export const averageScore = (data) => {
	return axios({
		url: "/api/v1/activity/queryAverageFractionList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//获取平均评分列表
export const statisticalAverage = (data) => {
	return axios({
		url: "/api/v1/activity/queryAverageFractionStatistics",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//导出平均评分列表
export const exportAverage = (data) => {
	return axios({
		url: "/api/v1/activity/exportAverageFractionExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//评分清除
export const clearRating = (data) => {
	return axios({
		url: "/api/v1/activity/clearFraction",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事列表
export const doingThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/queryDoingPracticalThingsPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事添加
export const addThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/saveDoingPracticalThings",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事详情
export const infoThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/queryDoingPracticalThingsInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事编辑
export const editThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/editDoingPracticalThings",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事领奖名单
export const inThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/queryDoingPracticalThingsRegistrationPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事领奖名单导出
export const outThings = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/exportDoingPracticalThingsRegistrationExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//我为群众办实事新增上传接口
export const ssPictures = (data) => {
	return axios({
		url: "/api/v1/doingPracticalThings/saveImage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 子女托管列表
export const ZNfigure = (data) => {
	return axios({
		url: "/child/childCustodyShowList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管添加
export const ZNpresent = (data) => {
	return axios({
		url: "child/addChildCustodyShow",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管编辑获取信息
export const ZNredact = (data) => {
	return axios({
		url: "child/childCustodyShowInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管编辑保存信息
export const ZNsave = (data) => {
	return axios({
		url: "child/updateChildCustodyShow",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管删除
export const ZNdelete = (data) => {
	return axios({
		url: "child/deleteChildCustodyShow",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 活动风采列表接口
export const FClist = (data) => {
	return axios({
		url: "/previous/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 活动风采列表添加
export const FCadd = (data) => {
	return axios({
		url: "/previous/addPreviousData",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 活动风采列表编辑
export const FCredact = (data) => {
	return axios({
		url: "/previous/previousDataInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 活动风采列表编辑提交
export const FCsubmit = (data) => {
	return axios({
		url: "/previous/updatePreviousData",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 活动风采列表删除
export const FCdelete = (data) => {
	return axios({
		url: "/previous/deletePreviousData",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 积分统计(已派发积分、在途积分、已换购积分) 以及 工会可派发积分
export const PFintegral = (data) => {
	return axios({
		url: "/integral/statistical",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取积分的派发日志
export const FYintegral = (data) => {
	return axios({
		url: "/integral/distributionIntegralLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 下载模板
export const FYdownload = (data) => {
	return axios({
		url: "/integral/downloadExcelExample",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 失败日志
export const FYdefeated = (data) => {
	return axios({
		url: "/integral/distributionIntegralErrorLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 购买积分日志
export const FYpurchase = (data) => {
	return axios({
		url: "/integral/buyIntegralLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量导入
export const FYdaoru = (data) => {
	return axios({
		url: "/integral/uploadExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "multipart/form-data",
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 余额统计列表
export const YEbalance = (data) => {
	return axios({
		url: "/integral/userList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 余额积分明细
export const YEintegral = (data) => {
	return axios({
		url: "/integral/integralChangeLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 余额现金券明细
export const YEcertif = (data) => {
	return axios({
		url: "/cash/cashChangeLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 消费统计
export const XFstatistics = (data) => {
	return axios({
		url: "/goods/goodsBuyList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会列表
export const SportsAssociation = (data) => {
	return axios({
		url: "/api/v1/association/queryAssociationPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会添加
export const associationAdd = (data) => {
	return axios({
		url: "/api/v1/association/saveAssociation",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会编辑信息
export const associationInfo = (data) => {
	return axios({
		url: "/api/v1/association/queryAssociationInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会编辑信息保存
export const associationEdit = (data) => {
	return axios({
		url: "/api/v1/association/editAssociation",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会报名统计
export const registrationStatistics = (data) => {
	return axios({
		url: "/api/v1/association/queryRegistrationStatisticsPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会报名统计导出
export const exportRegistration = (data) => {
	return axios({
		url: "/api/v1/association/exportRegistrationStatisticsExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会管理员列表
export const associationAdministrator = (data) => {
	return axios({
		url: "/api/v1/association/queryAssociationAdminList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会管理员添加
export const administratorAdd = (data) => {
	return axios({
		url: "/api/v1/association/saveAssociationAdmin",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//文体协会管理员删除
export const administratorDle = (data) => {
	return axios({
		url: "/api/v1/association/deleteAssociationAdmin",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//会员协会统计列表
export const membershipStatistics = (data) => {
	return axios({
		url: "/api/v1/association/queryUserAssociationPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//会员协会统计导出
export const statisticalExport = (data) => {
	return axios({
		url: "/api/v1/association/exportUserAssociationExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//会员协会报名统计手动延期
export const manualExtension = (data) => {
	return axios({
		url: "/api/v1/association/editManualExtension",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
//会员协会报名统计延期日志
export const delayLog = (data) => {
	return axios({
		url: "/api/v1/association/queryManualExtensionPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 所属协会
export const SSassociation = (data) => {
	return axios({
		url: "/api/v1/association/queryPublicAssociationList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 往期回顾添加
export const ADDreview = (data) => {
	return axios({
		url: "/api/v1/association/savePrevious",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':"application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 往期回顾列表
export const Previous = (data) => {
	return axios({
		url: "/api/v1/association/queryPreviousPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 往期回顾信息编辑
export const HGredact = (data) => {
	return axios({
		url: "/api/v1/association/queryPreviousInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 往期回顾保存信息编辑
export const BCredact = (data) => {
	return axios({
		url: "/api/v1/association/editPrevious",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':"application/x-www-form-urlencoded",
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量回收
export const HSintegral = (data) => {
	return axios({
		url: "/integral/withdrawIntegralUploadExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "multipart/form-data",
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 批量回收获取数据
export const HSinformation = (data) => {
	return axios({
		url: "/integral/withdrawIntegralLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 批量回收失败获取数据
export const HSdefeated = (data) => {
	return axios({
		url: "/integral/withdrawIntegralErrorLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 批量回收积分下载模板
export const HSdownload = (data) => {
	return axios({
		url: "/integral/downloadWithdrawExcelExample",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量代扣
export const DKintegral = (data) => {
	return axios({
		url: "/integral/deductIntegralUploadExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "multipart/form-data",
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量代扣积分下载模板
export const DKdownload = (data) => {
	return axios({
		url: "/integral/downloadDeductExcelExample",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量代扣获取数据
export const DKinformation = (data) => {
	return axios({
		url: "/integral/deductIntegralLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 批量代扣失败数据
export const DKdefeated = (data) => {
	return axios({
		url: "/integral/deductIntegralErrorLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取系统所有下载信息
export const XZall = (data) => {
	return axios({
		url: "/union/unionDownload",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取系统所有查询系统任务
export const RWall = (data) => {
	return axios({
		url: "/union/unionSystemTasks",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 派发积分合计
export const PFtotal = (data) => {
	return axios({
		url: "/integral/exportStatisticalIntegralExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 派发积分明细
export const PFdetail = (data) => {
	return axios({
		url: "/integral/exportStatisticalIntegralDetailExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 在途积分合计
export const ZTtotal = (data) => {
	return axios({
		url: "/integral/exportEffectiveIntegralExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 换购积分合计
export const HGtotal = (data) => {
	return axios({
		url: "/integral/exportConsumeIntegralExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 过期积分合计
export const GQtotal = (data) => {
	return axios({
		url: "/integral/exportInvalidIntegralExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 过期积分明细
export const GQdetail = (data) => {
	return axios({
		url: "/integral/exportInvalidIntegralDetailExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取积分明细数据
export const GQlist = (data) => {
	return axios({
		url: "/integral/invalidIntegralLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 手动过期
export const GQhand = (data) => {
	return axios({
		url: "/integral/handInvalidIntegral",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 获取会费缴纳列表
export const HFlist = (data) => {
	return axios({
		url: "/dues/list",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取会费缴纳参数列表
export const HFparameters = (data) => {
	return axios({
		url: "/dues/paymentParametersList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会费缴纳参数添加
export const HFparametersadd = (data) => {
	return axios({
		url: "/dues/addPaymentParameters",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会费缴纳编辑参数获取
export const HFparametersdit = (data) => {
	return axios({
		url: "/dues/paymentParametersInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会费缴纳编辑参数保存
export const HFparameterssave = (data) => {
	return axios({
		url: "/dues/editPaymentParameters",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会费缴纳按月导出
export const HFmonthly = (data) => {
	return axios({
		url: "/dues/duesPayMonthExportExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 会费缴纳按年导出
export const HFyear = (data) => {
	return axios({
		url: "/dues/duesPayYearExportExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取会员缴费记录
export const HFpaging = (data) => {
	return axios({
		url: "/dues/duesPayLogList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取会员缴费规则列表
export const HFshape = (data) => {
	return axios({
		url: "/dues/ruleList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 分页获取会员缴费规则列表
export const HFruleadd = (data) => {
	return axios({
		url: "/dues/addRule",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取缴纳编辑规则数据
export const HFdatabase = (data) => {
	return axios({
		url: "/dues/ruleInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取缴纳保存编辑规则数据
export const HFpreserve = (data) => {
	return axios({
		url: "/dues/editRule",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取缴费金额列表
export const HFfigure = (data) => {
	return axios({
		url: "/dues/paySetUpList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 下载导入缴费模板
export const HFdownload = (data) => {
	return axios({
		url: "/dues/downloadExcelExample",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 导入缴费接口
export const HFchannel = (data) => {
	return axios({
		url: "/dues/setUpPayDataUploadExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': "multipart/form-data",
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 导出-会员缴费设置金额数据
export const HFderive = (data) => {
	return axios({
		url: "/dues/exportDuesSetUpPayExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

export const HFzhezhijiaofei = (data) => {
	return axios({
		url: "/dues/paySetUp",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取会费操作日志
export const HFoperate = (data) => {
	return axios({
		url: "/dues/duesLogList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 分页获取会费操作错误日志
export const HFmistake = (data) => {
	return axios({
		url: "/dues/duesErrorLogList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管报名列表
export const ZNapply = (data) => {
	return axios({
		url: "/child/childCustodyList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名添加报名表
export const ZNentry = (data) => {
	return axios({
		url: "/child/addChildCustody",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名获取报名表
export const ZNeditx = (data) => {
	return axios({
		url: "/child/childCustodyInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名获取报名表保存编辑
export const ZNcompile = (data) => {
	return axios({
		url: "/child/updateChildCustody",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管班级列表
export const ZNgrade = (data) => {
	return axios({
		url: "/child/childCustodyClassList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名添加班级
export const ZNgradeadd = (data) => {
	return axios({
		url: "/child/addChildCustodyClass",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名获取班级
export const ZNBJgain = (data) => {
	return axios({
		url: "/child/childCustodyClassInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女报名保存编辑班级
export const ZNBJsave = (data) => {
	return axios({
		url: "/child/updateChildCustodyClass",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管外部工会列表
export const ZNexterior = (data) => {
	return axios({
		url: "/child/childCustodyUnionList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管外部工会列表添加
export const ZNexterioradd = (data) => {
	return axios({
		url: "/child/addChildCustodyUnion",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管外部工会获取编辑信息
export const ZNWBredact = (data) => {
	return axios({
		url: "/child/childCustodyUnionInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管外部工会保存编辑信息
export const ZNWBpreserve = (data) => {
	return axios({
		url: "/child/updateChildCustodyUnion",
		method: "post",
		data,
		config: {
			headers: {
				// 'Content-Type':'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管查看二维码
export const ZNscanning = (data) => {
	return axios({
		url: "/child/childCustodyRegistrationCode",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管查看报名
export const ZNexamine = (data) => {
	return axios({
		url: "/child/childCustodyRegistrationList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 子女托管查看报名导出
export const ZNderive = (data) => {
	return axios({
		url: "/child/exportRegistrationExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

//获取工会组织名称
export const TYderiver = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/queryUnionList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};





//获取管理员列表
export const SZargur = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/queryUnionAdminList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

//管理员导出
export const AdministratorExport = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/exportAdminLoginLogExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


//添加管理员
export const SZglur = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/saveUnionAdmin",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};



// 删除管理员
export const SCdelet = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/deleteUnionAdmin",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};



// 获取流量列表
export const SCaddmin = (data) => {
	return axios({
		url: "/api/v1/flow/queryFlowList",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};


// 获取管理员列表
export const LLoginLog = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/queryAdminLoginLog",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 导出管理员登录
export const DCoginLog = (data) => {
	return axios({
		url: "/api/v1/unionAdmin/exportAdminLoginLogExcel",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 添加流程
export const AddProcess = (data) => {
	return axios({
		url: "/api/v1/flow/saveFlow",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 获取审批人信息
export const ObtainApprover = (data) => {
	return axios({
		url: "/api/v1/flow/queryUserInfo",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 流程详情
export const ProcessInfo = (data) => {
	return axios({
		url: "/api/v1/flow/queryFlow",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};

// 编辑流程
export const EditProcess = (data) => {
	return axios({
		url: "/api/v1/flow/editFlow",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/json',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 积分返还列表
export const PointsList = (data) => {
	return axios({
		url: "/integral/queryRefundIntegralPage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};
// 短信推送列表
export const smsPush = (data) => {
	return axios({
		url: "/api/v1/message/queryMessagePage",
		method: "post",
		data,
		config: {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'accessToken': sessionStorage.getItem('token')
			},
		},
	});
};